import React, { useState } from "react";
import { FluidObject } from "gatsby-image";
import styled from "styled-components";

import { SanityPeople, Maybe } from "@graphql-types";
import { Container, P } from "@util/standard";
import { AnimationContainer, Image } from "@sub";
import {
  altTexts,
  animationType,
  ANIMATION_TRANSITION,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { getRandomArbitrary } from "@util/helper";

const Wrapper = styled(Container)`
  width: 100%;
  flex-direction: column;
`;

const ImageContainer = styled(Container)<{}>`
  width: 100%;
  margin: 0;
  position: relative;
  overflow: hidden;
  height: 100%;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 300px;
  }
`;

const SkuxxImagesContainer = styled.div<{ hover?: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity ${ANIMATION_TRANSITION}s linear;
  ${({ hover }) =>
    hover &&
    `&:hover {
    opacity: 1;
  }`};
`;

const RowContainer = styled(Container)`
  width: 100%;
  height: 100%;
  margin: 0;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const FixImageContainer = styled(Container)`
  width: 88px;
  height: 88px;
  margin: 0;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
  }
`;

const StyledName = styled(P)<{ hover?: boolean }>`
  width: fit-content;
  font-size: 16px;
`;

interface Props {
  person: Maybe<SanityPeople>;
  showAsRow?: boolean;
}

const PeoplePreview = ({ person, showAsRow }: Props) => {
  if (person == null) {
    return null;
  }

  const [hover, setHover] = useState(false);
  const role = person.roles?.map((role) => role?.title).join(" & ");

  const SkuxxImage = () => {
    if (person.altImages == null) {
      return null;
    }

    return person.altImages == null ? null : (
      <SkuxxImagesContainer hover={hover ? hover : undefined}>
        <Image
          grayscale={90}
          fluid={
            person.altImages[getRandomArbitrary(0, person.altImages.length - 1)]
              ?.asset?.fluid as FluidObject
          }
          isBackground
          backgroundStyle={{
            width: "100%",
            filter: "grayscale(90)",
            padding: "0 0 100% 0",
          }}
          altText={altTexts[getRandomArbitrary(0, 2)]}
        />
      </SkuxxImagesContainer>
    );
  };

  if (showAsRow) {
    return (
      <RowContainer>
        <FixImageContainer>
          <Image
            isBackground
            altText={altTexts[getRandomArbitrary(0, 2)]}
            fluid={person.image?.asset?.fluid as FluidObject}
            backgroundStyle={{
              width: "100%",
              padding: "0 0 100% 0",
            }}
          />
        </FixImageContainer>
        <Container
          width="auto"
          margin="0 0 0 12px"
          flexDirection="column"
          justifyContent="center"
        >
          <P fontSize={16} noMargin bold>
            {person.name}
          </P>
          <P noMargin>{role}</P>
        </Container>
      </RowContainer>
    );
  }

  return (
    <AnimationContainer animationType={animationType.slideInBottom}>
      <Wrapper>
        <ImageContainer
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <Image
            fluid={person.image?.asset?.fluid as FluidObject}
            isBackground
            backgroundStyle={{
              width: "100%",
              padding: "0 0 100% 0",
            }}
          />
          <SkuxxImage />
        </ImageContainer>
        <Container width="100%" margin="15px auto" flexDirection="column">
          <StyledName noMargin bold hover={hover}>
            {person.name}
          </StyledName>
          <P fontSize={16} noMargin>
            {role}
          </P>
        </Container>
      </Wrapper>
    </AnimationContainer>
  );
};

export default PeoplePreview;

import React, { ReactNode } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import { Container, GridContainer } from "@util/standard";
import { Query } from "@graphql-types";
import { AnimationContainer, BlocksContent } from "@sub";
import TeamSlider from "./teamSlider";
import { animationType, TABLET_BREAKPOINT } from "@util/constants";
import PeoplePreview from "@components/shared/peoplePreview";

const TextContainer = styled(Container)`
  width: 70%;
  margin: 0;
  margin-bottom: 50px;

  .heading-1 {
    margin: 0;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 90%;
    margin: 30px auto;
    .heading-1 {
      margin: 0;
      font-size: 50px !important;
    }
  }
`;

const StyledGridContainer = styled(GridContainer)`
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 90%;
  }
`;

const People = () => {
  const { allSanityPeople, sanityAbout }: Query = useStaticQuery(graphql`
    {
      allSanityPeople(sort: { fields: order, order: ASC }) {
        edges {
          node {
            roles {
              id
              title
            }
            image {
              asset {
                fluid {
                  srcWebp
                  srcSetWebp
                  ...GatsbySanityImageFluid
                }
              }
            }
            altImages {
              _key
              asset {
                fluid {
                  srcWebp
                  srcSetWebp
                  ...GatsbySanityImageFluid
                }
              }
            }
            id
            name
          }
        }
      }
      sanityAbout {
        heroText {
          _rawColumnContent
        }
      }
    }
  `);

  if (allSanityPeople == null) {
    return null;
  }

  const Animated = ({ children }: { children: ReactNode }) => (
    <AnimationContainer animationType={animationType.slideInBottom}>
      {children}
    </AnimationContainer>
  );

  const nodes = allSanityPeople.edges.map((edge) => edge.node);

  return (
    <Container
      mobileDimensions={{ width: "100%" }}
      flexDirection="column"
      mobileMargin="100px 0 0 0"
      width="90%"
      mobileWidth="100%"
      margin="250px auto 140px auto"
    >
      <Animated>
        <TextContainer>
          <BlocksContent blocks={sanityAbout?.heroText?._rawColumnContent} />
        </TextContainer>
      </Animated>
      <StyledGridContainer
        margin="0 auto 80px auto"
        rowGap="20px"
        columnGap="15px"
        width="100%"
      >
        {nodes.map((node) => {
          return <PeoplePreview key={node.id} person={node} />;
        })}
      </StyledGridContainer>

      <Animated>
        <TeamSlider />
      </Animated>
    </Container>
  );
};

export default People;
